import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimePickerComponent} from './timepicker.component';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [TimePickerComponent],
  exports: [
    TimePickerComponent
  ],
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class TimepickerModule { }
