import {Pipe, PipeTransform} from "@angular/core";


@Pipe({
  name: 'filter_byArray'
})

export class DataFilterArrayPipe implements PipeTransform{
  transform(items: any[], value: string,key?:string): any[] {

    if (!items) {
      return [];
    }
    if (!value) {
      return items;
    }

    return items.filter( it => {
      return it.toLowerCase().includes(value.toLowerCase());
    });

  }
}




